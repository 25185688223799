import { Link } from "react-router-dom";
import logoDark from "../assets/logo.png";

const Header = () => {
	const toggleNavigation = async() => {
		if(!document.querySelector("#menu-container").style.display){
			document.querySelector("#menu-container").style.setProperty('display', 'block');
			document.querySelector("#body-page").classList.add('primary-menu-open');
		}else{
			document.querySelector("#menu-container").style.removeProperty('display');
			document.querySelector("#body-page").classList.remove('primary-menu-open');
		}
	}

    const scrollMission = async() => {
        document.querySelector("#mission").scrollIntoView({ behavior: 'smooth' });
    }

	const scrollService = async() => {
        document.querySelector("#service").scrollIntoView({ behavior: 'smooth' });
    }

	const scrollTeam = async() => {
        document.querySelector("#team").scrollIntoView({ behavior: 'smooth' });
    }

	const scrollContact = async() => {
        document.querySelector("#contact").scrollIntoView({ behavior: 'smooth' });
    }

  	return (
		<header id="header" className="full-header">
			<div id="header-wrap" className="header-transparent">
				<div className="container">
					<div className="header-row">

						<div id="logo">
							<Link to={"/"} className="standard-logo" data-dark-logo={logoDark}><img src={logoDark} alt="truclimate" style={{width:"150px", height:"auto"}} /></Link>
							<Link to={"/"} className="retina-logo" data-dark-logo={logoDark}><img src={logoDark} alt="truclimate" style={{width:"150px", height:"auto"}} /></Link>
						</div>

						<div id="primary-menu-trigger" onClick={() => toggleNavigation()}>
							<svg className="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>
						</div>

						<nav className="primary-menu">

							<ul id="menu-container" className="menu-container" style={{border:"0px"}}>
								<li className="menu-item">
									<Link onClick={() => scrollMission()} className="menu-link"><div>Our Mission</div></Link>
								</li>
								<li className="menu-item">
									<Link onClick={() => scrollService()} className="menu-link"><div>Our Services</div></Link>
								</li>
								<li className="menu-item">
									<Link onClick={() => scrollTeam()} className="menu-link"><div>The Team</div></Link>
								</li>
								<li className="menu-item">
									<Link onClick={() => scrollContact()} className="menu-link"><div>Contact Us</div></Link>
								</li>
							</ul>

						</nav>

					</div>
				</div>
			</div>
			<div className="header-wrap-clone"></div>
		</header>
  	)
}

export default Header