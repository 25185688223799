import React, { useState, useEffect } from "react";
import emphasisLogo from "../assets/Emphasis-logo.png"
import team1 from "../assets/home/team-1.png"
import team2 from "../assets/home/team-14.png"
import team3 from "../assets/home/team-3.png"
// eslint-disable-next-line
import team4 from "../assets/home/team-4.png"
import team6 from "../assets/home/team-6.png"
import team10 from "../assets/home/team-10.png"
import team11 from "../assets/home/team-11.png"
import team15 from "../assets/home/team-15.png"
import { BsArrowDownRightCircle, BsArrowUpCircle } from "react-icons/bs";
import AOS from "aos";
import "aos/dist/aos.css";
import '../styles/index.css';

const constantTeamMember = [
  {
    row: 1,
    id: 'row-1',
    collapse: false,
    member: [
      /*{
        name: 'ERI BUDIONO',
        position: 'Founder',
        image: team1,
        col: 3,
        id: 'team-1',
        description: `Eri Budiono is a seasoned banker with 30-year experience in commercial and investment banking at international and regional banks, dealing with local large corporations to small to medium-sized businesses.`,
        description2: `His extensive network and connection with numerous businesses and Financial Institutions has provided valuable insights as to how businesses can transition smoothly in their climate journey.`,
        description3: `During his tenure in HSBC, he was responsible for driving the Sustainable Finance strategy for the bank's Commercial Banking business through the provision of Green Financing and Sustainability-Linked Loans as well as the facilitation of ESG advisory services and decarbonization planning.`
      },*/
      {
        name: 'YUDHA PERMANA',
        position: 'Chief Executive Officer',
        image: team2,
        col: 4,
        id: 'team-2',
        description: `Yudha Permana Jayadikarta brings over 18 years of expertise in ESG, Sustainability, Energy Transition, Social Impact, and Sustainable Finance. His journey is adorned with significant roles at esteemed institutions like Amman Mineral and Indonesia Weda Bay Industrial Park (IWIP), known for their contributions to copper mining and mega-nickel Chinese integrated mining and smelter projects. Additionally, his tenure at the World Bank adds to his illustrious career.`,
        description2: `In various industries, Yudha has demonstrated leadership in Renewable Energy initiatives. Currently serving as the executive director at IRES (Indonesian Renewable Energy Society), he is dedicated to steering towards a greener future. With a Bachelor's in Economics from Airlangga University and a Master's in Finance from the University of Indonesia, Yudha leads efforts towards a sustainable and prosperous tomorrow.`
      },
      {
        name: 'PETRUS GUNARSO',
        position: 'Technical & Strategic Advisor',
        image: team3,
        col: 4,
        id: 'team-3',
        description: `Petrus Gunarso has over 30 years of experiences ranging from forestry, climate change, sustainable financing and conservation as expert and executives for national and international projects. Petrus Gunarso currently serves as President Commissioner of PT MOHI, a green investment company. Previously, Petrus was director and senior advisor to APRIL Group for sustainability aspect.`,
        description2: `Being an active member of prominent organizations, which worked closely with various stakeholders, he has robust understanding in sustainability and conservation and extensive network with ministries, local authorities, financing institutions, and private sectors. Dr. Gunarso also has published writings and delivered presentations in multiple international forums related to his abovementioned expertise.`
      },
      /*{
        name: 'RANDY ISMAIL',
        position: 'Technical & Strategic Advisor',
        image: team4,
        col: 3,
        id: 'team-4',
        description: `Randy Ismail is a seasoned consultant with over 20 years of experience in climate change, sustainability, industrial process and energy efficiency related to greenhouse gas emissions.`,
        description2: `Besides being frequently engaged as a trainer for ISO 14064-1, 14064-2, 14064-3, 14065, 14067 certification, his expertise in assessing organization/company-level/project-level GHG inventory has also allowed him to advise multiple corporate clients on numerous decarbonization projects across Indonesia.`,
        description3: `His in-depth understanding and extensive knowledge had him plotted to be the Managing Partner in a leading global sustainability consultant. Through his consultancy work, he has helped various large local corporates in GHG inventory reporting, formulating their decarbonization roadmap and helping them navigate through their climate change journey.`
      },*/
      {
        name: 'LEO WIBOWO SETIA',
        position: 'GIS and Remote Sensing Expert',
        image: team6,
        col: 4,
        id: 'middle-1',
        description: `Leo is a senior GIS expert, specialized in Remote Sensing and Photogrammetry application for environment monitoring especially in peatland , LULCC, carbon stock analysis, carbon emissions and HCV. He has involved in conservation activities by acquiring, processing and analyzing spatial data to support all company activities in accordance with Indonesian Government standards, Verified Carbon Standard Community Climate Biodiversity (VCS CCB), SD Vista, and Sustainable Development Goals.`,
        description2: `He is equipped with license of Tenaga Teknis PH Pengukuran dan Perpetaan (GANIS KURPET) from Ministry of Environment and Forestry Republic of Indonesia and Bachelor of Geography from Universitas Negeri Semarang.`
      },
    ]
  },
  {
    row: 2,
    id: 'row-2',
    collapse: false,
    member:
      /*[
        {
          name: 'HARYO AJIE DEWANTO',
          position: 'Forestry and Peatland Restoration',
          position2: 'Senior Consultant',
          image: team5,
          col: 3,
          id: 'middle-1',
          description: `Haryo is a highly experienced and dedicated forestry and conservation professional with a strong background in environmental management, climate mitigation, and community development. He holds a Bachelor of Forestry degree from Universitas Gadjah Mada, Yogyakarta, Indonesia, and a Master of Science in Natural Resources from the University of Missouri, Columbia, USA. His graduate thesis focused on the participatory approach in spatial modeling for forest ecosystem restoration in peatland landscapes.`,
          description2: `Haryo has extensive work experience, having held various positions at PT. Rimba Raya Conservation, including Climate Program Manager, General Manager, and currently serving as the Technical Director. He has also worked with international organizations such as the United Nations Office for Project Services (UNOPS) and the HCV Resource Network Ltd. He is a passionate advocate for the environment and committed to preserving Indonesia's rich biodiversity while promoting sustainable development and climate mitigation efforts.`
        },*/
      [
        {
          name: 'ANDREAN ARDHITYA',
          position: 'FOLU Carbon Project Coordinator',
          image: team10,
          col: 4,
          id: 'middle-2',
          description: `A Bachelor of Forestry from Universitas Gadjah Mada, has earned numerous national and international medals during his college studies.`,
          description2: `His passion for forest carbon led him to develop an innovative carbon allometric model for his thesis, enabling accurate prediction of carbon sequestration in a specific bamboo species. His dedication to environmental conservation and sustainable forestry practices contributes significantly to the global efforts in combating climate change.`
        },
        {
          name: 'SHADRINA RAMADHANI',
          position: 'Biodiversity Expert',
          image: team11,
          col: 4,
          id: 'middle-3',
          description: `As an avid fan of environmental improvement and conservation, Shadrina involved in numerous biodiversity research throughout her careers. Her main topics of interest during her study mainly revolved around the effect of climate change in different organisms.`,
          description2: `She obtained her bachelor in Biology from Universitas Gadjah Mada, Indonesia before achieving her master degree from Biodiversity and Sustainability programme in Leiden University, The Netherlands. She experienced in supporting the land-use based mitigation and technology project as well as clean energy transition project. She completed the ISO/IEC 17025:2017 and ISO 31000 training and holds Enterprise Risk Management Associate Professional (ERMAP) certification from Enterprise Risk Management Academy.`
        },
        {
          name: 'GERY NOVRIAN',
          position: 'GIS & AFOLU Carbon Project Analyst',
          image: team15,
          col: 4,
          id: 'middle-4',
          description: `Gery, is a forestry graduate from Universitas Gadjah Mada, consistently engaged diverse research projects integrating geospatial technology and forestry science. His commitment to innovative solutions is evident in active roles covering sustainable forest management, including Forest Planning, Carbon Accounting, Biodiversity, Land Reclamation, GIS, Remote Sensing, and UAV technology. Notably, Gery has played a pivotal role as a GIS Analyst & Reclamation Engineer, specializing in carbon calculation and mine reclamations within mining areas, showcasing his expertise in both theoretical forestry concepts and practical geospatial technology applications.`
        },
      ]
  },
  {
    row: 3,
    id: 'row-3',
    collapse: false,
    member: [

    ]
  },
]

const Home = () => {
  const [linkLinkedin, setLinkLinkedin] = useState([]);
  //const [linkInstagram, setLinkInstagram] = useState([]);
  const [linkWhatsapp, setLinkWhatsapp] = useState([]);
  const [linkMail, setLinkMail] = useState([]);
  const [linkMaps, setLinkMaps] = useState([]);
  const [memberId, setMemberId] = useState('team-1')
  // const [rowId, setRowId] = useState('row-1')
  const [teamMember, setTeamMember] = useState(constantTeamMember)

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

    useEffect(() => {
        setLinkLinkedin("https://linkedin.com/company/terrathrive");
        //setLinkInstagram("https://instagram.com/truclimate");
        //setLinkWhatsapp("https://wa.me/+6281212019666");
        //setLinkWhatsapp("https://wa.me/+6281275702780");
        setLinkWhatsapp("https://wa.me/+6282299458889");
        setLinkMail("mailto:hello@terrathrive.co.id");
        setLinkMaps("https://goo.gl/maps/eoJvXyDoFxXwrVvm6");
        window.scrollTo(0, 0);
    }, []);

  const scrollMission = async () => {
    document.querySelector("#mission").scrollIntoView({ behavior: 'smooth' });
  }

  const toggleService = async (number) => {
    if (number === 1) {
      const serviceOne = document.querySelector("#service-1");

      const displayValue = serviceOne.style.display;
      if (displayValue === "none") {
        serviceOne.style.display = "block"
      } else {
        serviceOne.style.display = "none"
      }
    } else if (number === 2) {
      const serviceTwo = document.querySelector("#service-2");

      const displayValue = serviceTwo.style.display;
      if (displayValue === "none") {
        serviceTwo.style.display = "block"
      } else {
        serviceTwo.style.display = "none"
      }
    }
  }

  const toggleMember = (type, childrenId, memberData) => {

    if (memberData) setMemberId(memberData.id)

    const updatedTeamMembers = teamMember.map(row => {
      if (row.id === childrenId) {
        return {
          ...row,
          collapse: type === 'open' ? true : false
        }
      } else if (row.id !== childrenId) {
        return {
          ...row,
          collapse: false
        }
      }
      return row;
    });
    setTeamMember(updatedTeamMembers)
  }

  const convertData = (data) => {

    let objData = {
      name: 'ERI BUDIONO',
      position: 'Founder & Chief Executive Officer',
      image: team1,
      description: 'Eri Budiono is a seasoned banker with 30-year experience in commercial and investment banking at international and regional banks, dealing with local large corporations to small to medium-sized businesses.His extensive network and connection with numerous businesses and Financial Institutions has provided valuable insights as to how businesses can transition smoothly in their climate journey. During his tenure in HSBC, he was responsible for driving the Sustainable Finance strategy for the banks Commercial Banking business through the provision of Green Financing and Sustainability-Linked Loans as well as the facilitation of ESG advisory services and decarbonization planning'
    }

    const result = data.find((el) => {
      return memberId === el.id
    })

    objData = {
      ...result
    }
    return objData
  }


  return (
    <div>
      <section id="page-title" className="page-title-dark include-header home-hero-background">
        <div className="container clearfix">
          <div className="col-lg-12">
            <div className="heading-block mb-0 home-hero title-breadcrumb-white">
              <h2 className="mt-4 responsive-h2" data-aos='fade-left' data-aos-easing="ease-in-sine" data-aos-duration='10000'>Helping you navigate<br />the shift to sustainability</h2>
            </div>
            <p className="lead mb-2" data-aos='fade-left' data-aos-easing="ease-in-sine" data-aos-duration='10000'>ESG | Climate Change | Project Origination | Carbon Offsets | Impact Investing</p>
            <a href="#contact" onClick={() => scrollMission()} className="page-scroll"><i className="icon-line-corner-down-right" style={{ paddingRight: 15 }}></i>Act Now</a>
          </div>
        </div>

      </section>
      <div id="mission" className="row clearfix align-items-stretch">
        <div className="col-lg-6 col-padding my-5">
          <div className="heading-block">
            <h2 data-aos='fade-right' className="main-color title-section">OUR MISSION</h2>
          </div>
          <p data-aos='fade-right' className="main-color content-section mb-0">
            Through our in-depth knowledge on the local ESG dynamics & carbon trading landscape, we help to <b>accelerate the decarbonization</b> in Indonesia by:
            <br /><br />
            Advising local businesses on <b>navigating through their climate journey and fulfilling their net-zero commitments,</b> in order to support Indonesia’s journey in reaching the country’s NDC commitments as well as net zero target in 2060, and
            <br /><br />
            Realizing the country’s untapped potential in producing <b>high quality nature-based solutions</b>, especially in originating carbon credit projects, preserving and restoring its vast biodiversity, as well as helping the rural local communities to thrive in line with the UN SDGs.
          </p>
        </div>
        <div className="col-lg-6 center col-padding mission-background"></div>
      </div>
      <div id="emphasis-title">
        <div className="container clearfix py-5">
          <div className="row clearfix align-items-stretch">
            <div className="col-lg-3" style={{ margin: 'auto' }}>
              <img src={emphasisLogo} alt="truclimate" style={{ width: "100px", height: "auto", display: "block", margin: "0 auto" }} className="my-5" />
            </div>
            <div className="col-lg-9">
              <p className="m-5 secondary-color" data-aos='fade-right'>
                We understand that addressing the challenges of climate change for your organization is not an easy task. That’s why at TerraThrive we have assembled a diverse pool of experts from across the industries, with senior talents from Forestry, Climate Change, Energy Transition, ESG, Wildlife Conservation, Carbon Offsets, Investment, and Technology.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="service" className="pt-5">
        <div className="heading-block center mb-0 mt-5" style={{ border: 'none !important' }}>
          <h2 data-aos='fade-up' className="main-color title-section">OUR SERVICES</h2>
        </div>
      </div>
      <div className="row clearfix align-items-stretch">
        <div className="col-lg-6 center col-padding service-left-background"></div>
        <div data-aos='fade-left' className="col-lg-6 col-padding d-flex align-items-center">
          <div className="content-section">
            <p className="main-color lead">Sustainability & Climate Change</p>
            <ul className="main-color " style={{ paddingLeft: '1rem' }}>
              <li>ESG Strategy</li>
              <li>Corporate Climate Action</li>
              <li>Sustainability Reporting</li>
              <li>Assurance, Rating and Framework Alignment</li>
            </ul>
            <a href="#services" onClick={() => toggleService(1)} className="action-section">LEARN MORE&nbsp;&nbsp;<BsArrowDownRightCircle /></a>
          </div>
        </div>
      </div>
      <div id="service-1" className="bg-main-color" style={{ display: "none" }}>
        <div className="container clearfix">
          <div className="row clearfix align-items-stretch">
            <div className="col-lg-12 col-padding team-profile">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="mb-0 content-toggle">Sustainability & Climate Change Advisory</h1>
                <a href="#services" onClick={() => toggleService(1)}><BsArrowUpCircle className="content-toggle-icon" /></a>
              </div>
              <p className="mb-0 pt-1 mb-1"></p>
              <div className="team-profile-description pt-3">
                <p className="mb-0 pt-1 mb-1">TerraThrive offers comprehensive advisory services for sustainability and climate change, designed to help organizations achieve their sustainability goals and tackle the challenges posed by a rapidly changing environment.<br /><br />
                  Our services include:</p>
                <ul>
                  <li><span className="text-bold">ESG Strategy:</span><br />
                    We help organizations develop and implement effective Environmental, Social, and Governance (ESG) strategies, taking into account the latest trends, regulations, and stakeholder expectations.</li>
                  <li><span className="text-bold">Devising Corporate Climate Action:</span><br />
                    We work with organizations to set science-based targets, decarbonization roadmap, and implement low-carbon strategies that align with their business goals.</li>
                  <li><span className="text-bold">Sustainability Reporting:</span><br />
                    We assist organizations in producing high-quality sustainability reports that are transparent, credible, and aligned with the latest reporting frameworks and standards.</li>
                  <li><span className="text-bold">Assurance:</span><br />
                    Our team provides assurance services that give organizations the confidence that their sustainability reports are accurate, complete, and meet the required standards.</li>
                  <li><span className="text-bold">Rating and Framework Alignment:</span><br />
                    We help organizations understand and navigate the various sustainability ratings and frameworks, and ensure their sustainability initiatives are aligned with the latest industry standards.</li>
                </ul>
                <p>Our services are fully compatible with all the leading sustainability standards, ratings, and frameworks, including the POJK51, Global Reporting Initiative (GRI), the Sustainability Accounting Standards Board (SASB), and the Task Force on Climate-related Financial Disclosures (TCFD). With our expertise and commitment to sustainability, we can help organizations of all sizes make meaningful progress towards a more sustainable future.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row clearfix align-items-stretch" style={{ borderBottom: '1px solid #4C638C' }}>
        <div data-aos='fade-right' className="col-lg-6 col-padding d-flex align-items-center">
          <div className="content-section">
            <p className="main-color lead">Project Origination & Carbon Offsetting</p>
            <ul className="main-color " style={{ paddingLeft: '1rem' }}>
              <li>Impact Investing</li>
              <li>Carbon Offsetting for corporates</li>
              <li>Carbon Credits for investments</li>
            </ul>
            <a href="#services" onClick={() => toggleService(2)} className="action-section">LEARN MORE&nbsp;&nbsp;<BsArrowDownRightCircle /></a>
          </div>
        </div>
        <div className="col-lg-6 center col-padding service-right-background"></div>
      </div>
      <div id="service-2" className="bg-main-color" style={{ display: "none" }}>
        <div className="container clearfix">
          <div className="row clearfix align-items-stretch">
            <div className="col-lg-12 col-padding team-profile">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="mb-0 content-toggle">Project Origination & Carbon Offsetting</h1>
                <a href="#services" onClick={() => toggleService(2)}><BsArrowUpCircle className="content-toggle-icon" /></a>
              </div>
              <p className="mb-0 pt-1 mb-1"></p>
              <div className="team-profile-description pt-3">
                <p className="mb-0 pt-1 mb-1">TerraThrive specializes in providing advisory services for Carbon Project Origination, with a focus on the Indonesian landscape. Our team of experts has extensive experience in the fields of forestry, biodiversity, and carbon credits, and we work closely with project developers to help them build their projects from the ground up.<br /><br />
                  <span className="text-bold">Advisory services for Carbon Project Developers</span><br />
                  Our services are tailored to provide comprehensive support to project developers throughout the entire project lifecycle, starting from the initial planning and design phase to implementation and post-project monitoring. Our team of experts, with their extensive local knowledge and experience, offer guidance on the latest methodologies, regulations, and industry best practices. <br /><br />
                  <span className="text-bold">Impact Investing & Carbon Credits for Corporates</span><br />
                  TerraThrives provide services to help corporates and impact investors curate and choose high-quality carbon credits for offsetting and investment purposes. Our carbon credits projects are sourced and selected with a high standard of quality ensuring accountability of environmental and societal impact.<br /><br />
                  With a deep understanding of the Indonesian landscape and a commitment to sustainability,TerraThrive is well positioned to help organizations navigate the complex world of carbon project origination and drive positive results for stakeholders, the environment, and financial outcomes.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="team" className="pt-5">
        <div className="heading-block center mb-0 mt-5">
          <h2 data-aos='fade-up' className="main-color title-section">MEET THE TEAM</h2>
        </div>
      </div>

      <div className="section py-0">
        <div className="clearfix">
          {
            teamMember.map((children, i) => (
              <div
                className="row"
                style={{ margin: '0', justifyContent: 'center' }}
                key={i}
              >
                {
                  children.member.map((el, j) => (
                    <div key={j} className={`col-lg-${el.col} col-md-6`} style={{ margin: '30px 0', display: 'flex', justifyContent: 'center', alignContent: 'center', alignSelf: 'center', flexDirection: 'column', alignItems: 'center' }}>
                      <div className="team" style={{ width: '300px', }}>
                        <div className="team-image">
                          <img className="rounded-circle" src={el.image} alt={el.name} />
                        </div>
                        <div className="team-desc team-desc-bg" style={{ paddingBottom: '0' }}>
                          <div className="team-title"><p className="main-color">{el.name}</p><span>{el.position}<br/>{el.position2}</span></div>
                          {/*eslint-disable-next-line*/}
                          <a className="inline-block action-section" onClick={() => toggleMember('open', children.id, el)}>
                            Read more
                          </a>
                        </div>
                      </div>
                    </div >
                  ))
                }
                <div
                  id={children.id}
                  className={`bg-main-color collapse-team ${children.collapse ? 'active' : 'inactive'}`}
                >
                  <div className={`container clearfix `}>
                    <div className={`row clearfix align-items-stretch `}>
                      <div className={`col-lg-4 col-padding team-member-foto team-member-div mt-3`} style={{ margin: 'auto' }}>
                        <img
                          data-aos={children.collapse && 'fade-up'}
                          data-aos-duration='10000'
                          src={convertData(children.member).image}
                          alt="truclimate" style={{ height: "auto" }}
                          className={`rounded-logo-member `}
                        />
                      </div>
                      <div className="col-lg-8 col-padding team-profile">
                        <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'center', gap: '10px' }}>

                          <h1 className="mb-0" style={{ color: 'white' }}>{convertData(children.member).name}</h1>
                          {/*eslint-disable-next-line*/}
                          <a onClick={() => toggleMember('close', children.id)}>
                            <BsArrowUpCircle className="content-toggle-icon" />
                          </a>
                        </div>
                        <p className="mb-0 pt-1 mb-1" style={{ textTransform: 'uppercase', fontSize: '16px', color: 'white' }}>
                          <i>
                            {convertData(children.member).position}
                          </i>
                        </p>
                        <div className="team-profile-description pt-3">
                          <p>{convertData(children.member).description}</p><br/>
                          <p>{convertData(children.member).description2}</p><br/>
                          <p>{convertData(children.member).description3}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div >
            ))
          }
        </div >
      </div >

      <div id="contact">
        <div className="heading-block center mb-0 mt-5">
          <h2 className="main-color title-section">CONTACT US</h2>
        </div>
      </div>
      <div className="section py-0">
        <div className="container clearfix">

          <div className="row align-items-stretch col-mb-50 mb-0">
            <div className="col-lg-8">

              <div className="form-widget">
                <div className="form-process">
                  <div className="css3-spinner">
                    <div className="css3-spinner-scaler"></div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-4 form-group">
                    <label htmlFor="template-contactform-name">Name</label>
                    <input type="text" id="template-contactform-name" name="template-contactform-name" className="sm-form-control required" />
                  </div>

                  <div className="col-md-4 form-group">
                    <label htmlFor="template-contactform-email">Email</label>
                    <input type="email" id="template-contactform-email" name="template-contactform-email" className="required email sm-form-control" />
                  </div>

                  <div className="col-md-4 form-group">
                    <label htmlFor="template-contactform-phone">Phone</label>
                    <input type="text" id="template-contactform-phone" name="template-contactform-phone" className="sm-form-control" />
                  </div>

                  <div className="w-100"></div>

                  <div className="col-md-8 form-group">
                    <label htmlFor="template-contactform-subject">Subject</label>
                    <input type="text" id="template-contactform-subject" name="subject" className="required sm-form-control" />
                  </div>

                  <div className="col-md-4 form-group">
                    <label htmlFor="template-contactform-service">Services</label>
                    <select id="template-contactform-service" name="template-contactform-service" className="sm-form-control">
                      <option value="0">-- Select One --</option>
                      <option value="1">Emissions Accounting Platform</option>
                      <option value="2">Sustainability Reporting</option>
                      <option value="3">Carbon Project Development</option>
                      <option value="4">Carbon Offsets Purchases</option>
                      <option value="5">Request Eligibility Check</option>
                      <option value="6">General Enquiries</option>
                    </select>
                  </div>

                  <div className="w-100"></div>

                  <div className="col-12 form-group">
                    <label htmlFor="template-contactform-message">Message <small>*</small></label>
                    <textarea className="required sm-form-control" id="template-contactform-message" name="template-contactform-message" rows="6" cols="30"></textarea>
                  </div>

                  <div className="col-12 form-group d-none">
                    <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" className="sm-form-control" />
                  </div>

                  <div className="col-12 form-group">
                    <button className="button button-3d m-0" type="submit" id="template-contactform-submit" name="template-contactform-submit" value="submit">Send Message</button>
                  </div>
                </div>
              </div>

            </div>

            <div className="sidebar col-lg-4 product-detail">

              <a href={linkMaps} target="_blank" rel="noreferrer"><address>
                <strong>Address :</strong><br />
                Citylofts Sudirman Unit 820<br />
                Jl. K.H. Mas Mansyur No. 121<br />
                Central Jakarta, Indonesia 10220<br />
              </address></a>
              <strong>Phone :</strong><br />(+62) 822-9945-8889<br /><br />
              <strong>Email :</strong><br />hello@terrathrive.co.id

              <div className="widget border-0 pt-0">

                <a href={linkLinkedin} target="_blank" rel="noreferrer" className="social-icon si-dark si-linkedin">
                  <i className="icon-linkedin"></i>
                  <i className="icon-linkedin"></i>
                </a>

                <a href={linkWhatsapp} target="_blank" rel="noreferrer" className="social-icon si-dark si-whatsapp">
                  <i className="icon-whatsapp"></i>
                  <i className="icon-whatsapp"></i>
                </a>

                <a href={linkMail} className="social-icon si-dark si-email3">
                  <i className="icon-email3"></i>
                  <i className="icon-email3"></i>
                </a>

              </div>

            </div>
          </div>

        </div>
      </div>
    </div >
  )
}

export default Home