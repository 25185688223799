import { useState, useEffect } from "react";
import logo from "../assets/logo-2.png";

const Footer = () => {
    const [linkLinkedin, setLinkLinkedin] = useState([]);
	// eslint-disable-next-line
    const [linkInstagram, setLinkInstagram] = useState([]);
    const [linkWhatsapp, setLinkWhatsapp] = useState([]);
    const [linkMail, setLinkMail] = useState([]);

	useEffect(() => {
        setLinkLinkedin("https://linkedin.com/company/terrathrive");
        setLinkInstagram("https://instagram.com/truclimate");
        //setLinkWhatsapp("https://wa.me/+6281212019666");
		//setLinkWhatsapp("https://wa.me/+6281275702780");
        setLinkWhatsapp("https://wa.me/+6282299458889");
        setLinkMail("mailto:hello@terrathrive.co.id");
    }, []);

    return (
        <footer id="footer" className="dark">
			<div id="copyrights">
				<div className="container">

					<div className="row justify-content-between col-mb-30">
						<div className="col-12 col-lg-auto text-center text-lg-start order-last order-lg-first">
							<img src={logo} alt="terrathrive" className="mb-4" style={{width:"150px"}}/><br/>
						</div>

						<div className="col-12 col-lg-auto text-center text-lg-end">
							{/*<div className="copyrights-menu copyright-links">
								<a href="#">Home</a>/<a href="#">About</a>/<a href="#">Features</a>/<a href="#">Portfolio</a>/<a href="#">FAQs</a>/<a href="#">Contact</a>
							</div>*/}

							<a href={linkLinkedin} target="_blank" rel="noreferrer" className="social-icon inline-block si-borderless mb-0 si-linkedin">
								<i className="icon-linkedin"></i>
								<i className="icon-linkedin"></i>
							</a>
							<a href={linkWhatsapp} target="_blank" rel="noreferrer" className="social-icon inline-block si-borderless mb-0 si-whatsapp">
								<i className="icon-whatsapp"></i>
								<i className="icon-whatsapp"></i>
							</a>
							<a href={linkMail} className="social-icon inline-block si-borderless mb-0 si-email3">
								<i className="icon-email3"></i>
								<i className="icon-email3"></i>
							</a>
						</div>
					</div>

				</div>
			</div>
		</footer>
    )
}

export default Footer