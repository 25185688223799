import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <div id="wrapper" className="clearfix">
            <Header />
            <Home />
            <Footer />
          </div>
        } />
        <Route path='/' element={<Navigate to={'/'} />} />
      </Routes>
    </Router>
  );
}

export default App;
